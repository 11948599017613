import { Button, Checkbox, Form, Icon, Input, notification, Select, Typography } from 'antd';
import 'antd/dist/antd.css';
import { FormComponentProps } from 'antd/lib/form';
import TextArea from 'antd/lib/input/TextArea';
import dayjs from 'dayjs';
import React, { FormEvent, useContext } from 'react';
import InspectionContext from '../../context/inspection-context';
import { confirmFixedFindings } from '../../endpoints';
import { ConfirmationPersonFunction } from '../../models';
import { FindingPhotoUpload } from './finding-photo-upload';


const { Text } = Typography;
const { Option } = Select;



interface FindingConfirmationProps extends FormComponentProps {
    refresh?: () => void;
}

const FindingConfirmation = (props: FindingConfirmationProps) => {

    const { getFieldDecorator, getFieldValue } = props.form;

    const context = useContext(InspectionContext);

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();

        props.form.validateFields(async (err, values) => {
            if (!err) {
                context.inspection.confirmationInformation = {};
                context.inspection.confirmationInformation.contactFirstname = values.confirmationPersonFirstname;
                context.inspection.confirmationInformation.contactLastname = values.confirmationPersonName;
                context.inspection.confirmationInformation.contactPhone = values.confirmationPersonPhone;
                context.inspection.confirmationInformation.contactPersonFunction = values.confirmationPersonType;
                context.inspection.confirmationInformation.description = values.confirmationDescription;

                await confirmFixedFindings(context.inspection, context.securityToken);

                props.refresh();

                notification.open({
                    message: 'Mängelbehebung bestätigt',
                    description:
                      'Besten Dank für die Behebung der festgestellten Mängel. Ist eine Nachkontrolle nötig, meldet sich der Kontrolleur bei der zuständigen Person.',
                    icon: <Icon type="check" style={{ color: '#52c41a' }} />,
                  });
            }
        });
    }

    return (
        <div style={{ marginTop: "20px" }}>
            <h3>Mängelbehebung</h3>
            <p>Bei der Kontrolle wurden Mängel festgestellt. <br />
                Frist zur Mängelbehebung: <strong>{dayjs(context.inspection.followUpDeadline).format('DD.MM.YYYY')}</strong></p>

            <p>Gerne können Sie uns diese als behoben bestätigen: </p>

            <Form onSubmit={handleSubmit}>
                <Form.Item>
                    {getFieldDecorator('findingFixConfirmed', {
                        rules: [{
                            // required: true, message: 'Bitte bestätigen Sie die Mängelbehebung.',
                            validator: (rule, value, callback) => {
                                if (!value) { callback('Bitte bestätigen Sie die Mängelbehebung') } else {
                                    callback();
                                }
                            }
                        }]

                    })(
                        <Checkbox>sämtliche Mängel sind behoben</Checkbox>
                    )}
                </Form.Item>

                <p>Bitte teilen Sie uns mit, wer uns die Mängelbehebung bestätigt:</p>
                <Form.Item>
                    {getFieldDecorator('confirmationPersonName', {
                        rules: [{ required: true, message: 'Name' }]
                    })(
                        <Input placeholder="Name" />,
                    )}
                </Form.Item>

                <Form.Item>
                    {getFieldDecorator('confirmationPersonFirstname', {
                        rules: [{ required: true, message: 'Vorname' }]
                    })(
                        <Input placeholder="Vorname" />,
                    )}
                </Form.Item>

                <Form.Item>
                    {getFieldDecorator('confirmationPersonType', {
                        rules: [{ required: true, message: 'Bitte wählen Sie eine Funktion.' }]
                    })(
                        <Select
                            placeholder="Funktion"
                        // onChange={this.handleSelectChange}
                        >
                            <Option value={ConfirmationPersonFunction.Owner}>Eigentümer</Option>
                            <Option value={ConfirmationPersonFunction.Administrator}>Verwalter</Option>
                            <Option value={ConfirmationPersonFunction.Janitor}>Hauswart</Option>
                            <Option value={ConfirmationPersonFunction.Tinsmith}>Spengler</Option>
                            <Option value={ConfirmationPersonFunction.Electrician}>Elektriker</Option>
                        </Select>
                    )}
                </Form.Item>
                <Form.Item>
                    {getFieldDecorator('confirmationPersonPhone', {
                        rules: [{ required: true, message: 'Telefonnummer angeben.' }]
                    })(
                        <Input placeholder="Telefonnummer" />,
                    )}
                </Form.Item>
                <Form.Item>
                    {getFieldDecorator('confirmationDescription')(
                        <TextArea placeholder="Bemerkungen" rows={4} />,
                    )}
                </Form.Item>
                <FindingPhotoUpload />
                <div style={{ marginTop: "20px" }}>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" >Mängelbehebung bestätigen</Button>
                    </Form.Item>
                </div>
            </Form>
        </div>


    );
}

export default Form.create<FindingConfirmationProps>()(FindingConfirmation);