import { Address } from "./address";

export interface PortalInspection {
    id?: string,
    contactPersonName?: string,
    contactPersonPhone?: string,
    inspectorName?: string,
    assuranceNumber?: string,
    usage?: string,
    municipality?: string,
    location?: string,
    administrator?: Address,
    inspectionDate?: Date
    portalInspectionState?: PortalInspectionState,
    followUpDeadline?: Date,
    confirmationInformation?: ConfirmationInformation,
    kind?: InspectionKind,
    systemStatus?: number
}

export enum PortalInspectionState {
    Open = 0,
    ContactDataProvided = 1,
    AppointmentScheduled = 2,
    ConfirmationRequired = 3,
    Confirmed = 4,
    Processed = 5,
    Cancelled = 6
}

export enum ConfirmationPersonFunction {
    Unknown = 0,
    Owner = 1,
    Administrator = 2,
    Janitor = 3,
    Tinsmith = 4,
    Electrician = 5
}

export enum InspectionKind {
    Periodical = 0,
    FollowUp = 1,
    Initial = 2
}

export interface ConfirmationInformation {
    contactFirstname?: string,
    contactLastname?: string,
    contactPhone?: string,
    description?: string,
    contactPersonFunction?: ConfirmationPersonFunction;
    confirmationDate?: Date
}

