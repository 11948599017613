import React from "react";
import ReactDOM from "react-dom";
import axios, { AxiosInstance } from "axios";

/*require('es6-promise').polyfill();*/

const baseUrl: string = process.env.REACT_APP_API_BASE_URI || "";

const instance: AxiosInstance = axios.create({
  baseURL: baseUrl,
  headers: {
    "Access-Control-Allow-Origin": "*",
    Pragma: "no-cache"
  }
});

export default instance;
