import { Upload } from 'antd';
import 'antd/dist/antd.css';
import React, { useContext, useState } from 'react';
import InspectionContext from '../../context/inspection-context';
import { createDocument, deleteDocument, uploadDocument } from '../../endpoints';

const { Dragger } = Upload;

export const FindingPhotoUpload = () => {
    const context = useContext(InspectionContext);
    const [uploads, setUploads] = useState({});

    const handleUploadRequest = async (options: any) => {
        const portalDocument = await createDocument(context.inspection, context.securityToken, options.file.name);
        if (portalDocument) {
            uploads[options.file.uid] = portalDocument.id;
            // setUploads(uploads);
            await uploadDocument(portalDocument.uri, options.file);
        }

        options.onSuccess('', options.file);
    };

    const removeUploadedDocument = async () => {};

    return (
        <Dragger
            accept="image/*"
            multiple={true}
            customRequest={(options) => handleUploadRequest(options)}
            onRemove={async (f) => {
                await deleteDocument(uploads[f.uid], context.securityToken);
                delete uploads[f.uid];
            }}
        >
            <p className="ant-upload-hint">
                Ziehen oder klicken Sie in dieses Feld um Fotos der behobenen Mängel hochzuladen.
            </p>
        </Dragger>
    );
};
