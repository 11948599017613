import React from 'react';
import { PortalInspection } from '../models';


interface InspectionContextModel {
    inspection: PortalInspection
    securityToken?: string
}

const InspectionContext = React.createContext<InspectionContextModel>({
    inspection: {} as PortalInspection,
    securityToken: null
});

export default InspectionContext;