import React, { useState, useEffect } from 'react';

import { PropertyDetail } from './property-detail';
import { Typography, Descriptions } from 'antd';
import InspectionContext from '../../context/inspection-context';
import { getInspection } from '../../endpoints';
import { PortalInspection, PortalInspectionState, InspectionKind } from '../../models';
import ContactPersonDetails from './contact-person-details';
import { AppointmentInfo } from './appointment-info';
import FindingConfirmation from './finding-confirmation';
import { ConfirmationSummary } from './confirmation-summary';
import { InspectionStepsContainer } from './inspection-steps-container';

const { Text } = Typography;

export interface InspectionContainerProps {
    securityToken: string,
    assuranceNumber: string
}

export const InspectionContainer = (props: InspectionContainerProps) => {

    const { securityToken, assuranceNumber } = props;

    const [inspection, setInspection] = useState(null as PortalInspection);
    const [inspectionNotFound, setinspectionNotFound] = useState();

    const isInspectionCancelled = inspection && inspection.portalInspectionState === PortalInspectionState.Cancelled;

    const loadInspection = async () => {
        try {
            const inspection = await getInspection(assuranceNumber, securityToken);
            if (inspection) {
                setinspectionNotFound(false);
                setInspection(inspection)
            }
            else {
                setinspectionNotFound(true);
            }
        } catch (error) {
            console.log(error);
            setinspectionNotFound(true);
        }


    }

    const title =
        inspection?.kind === InspectionKind.Initial ? "Abnahme Blitzschutzanlage" :
            inspection?.kind === InspectionKind.Periodical ? "Periodische Blitzschutzkontrolle" :
                "Blitzschutzkontrolle";

    document.title = title;

    useEffect(() => {
        loadInspection();
    }, [])

    return (
        <InspectionContext.Provider value={{ inspection: inspection, securityToken: securityToken }}>
            <h2>{title}</h2>
            {!inspection && inspectionNotFound === undefined && (<div><Text>Lade Daten ...</Text></div>)}
            {inspectionNotFound && (<div><Text>Die Kontrolle konnte nicht gefunden werden.</Text></div>)}

            {inspection && <PropertyDetail />}

            {!isInspectionCancelled && (<InspectionStepsContainer inspection={inspection} onLoadInspection={loadInspection} />)}
            {isInspectionCancelled && (<div style={{ marginTop: "20px", marginBottom: "10px" }}><Text>Die Kontrolle wurde zwischenzeitlich zurückgezogen.</Text></div>)}

        </InspectionContext.Provider>
    );
}