import React from 'react';

import './App.css';
import { InspectionContainer } from '../blitzschutz';
import SecurityTokenChallenge from '../authorization/security-token-challenge'
import { Row, Col } from 'antd';
import { Typography } from 'antd';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Root } from './root';

const { Text } = Typography;


function App() {

    return (
        <Router>
            <Root />
        </Router >
    );
}

export default App;