import 'antd/dist/antd.css';
import React from 'react';
import InspectionContext from '../../context/inspection-context';
import { Descriptions, Row, Col } from 'antd';
import dayjs from 'dayjs';

export const AppointmentInfo = () => {

    const labelColumnStyle =
    {
       
       
    }
        ;

    return (
        <InspectionContext.Consumer>
            {ctx => (<div style={{ marginTop: "30px" }} >
                <h3>Termin</h3>
                <Row>
                    <Col span={10} style={labelColumnStyle}>Kontaktperson für Terminvereinbarung</Col>
                    <Col span={14}>{ctx.inspection.contactPersonName}</Col>
                </Row>
                <Row>
                    <Col span={10} style={labelColumnStyle}>Telefonnummer</Col>
                    <Col span={14}>{ctx.inspection.contactPersonPhone}</Col>
                </Row>
                {ctx.inspection.inspectionDate && (<Row>
                    <Col span={10} style={labelColumnStyle}>Vereinbarter Termin</Col>
                    <Col span={14}>{dayjs(ctx.inspection.inspectionDate).format('DD.MM.YYYY HH:mm')}</Col>
                </Row>)}

            </div>)}
        </InspectionContext.Consumer>


    );
}