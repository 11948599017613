import React, { FormEvent, useState } from 'react';
import { useHistory } from "react-router-dom";
import { Form, Icon, Input, Button, Checkbox } from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';
import 'antd/dist/antd.css';
import { getInspection } from '../../endpoints';

interface SecurityTokenChallengeProps extends FormComponentProps { }

export const SecurityTokenChallenge = (props: SecurityTokenChallengeProps) => {

    const { getFieldDecorator, getFieldValue } = props.form;
    const history = useHistory();
    const [isValidSecurityToken, setisValidSecurityToken] = useState();
    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        props.form.validateFields(async (err, values) => {
            if (!err) {

                try {

                    const inspection = await getInspection(values.assuranceNumber, values.securityToken);
                    if (inspection !== null) {
                        history.push(`/${values.assuranceNumber}/${values.securityToken}`);
                        return;
                    }
                }
                catch (error) {
                    setisValidSecurityToken(false);
                }                
            }
        });
    }

    return (
        <div>
            <h2 >Blitzschutzkontrolle</h2>
            {/* <Text>Bitte geben die Versicherungsnummer und das Passwort ein, das Sie von uns per Post erhalten haben.</Text> */}
            <Form onSubmit={handleSubmit}>
                <Form.Item>
                    {getFieldDecorator('assuranceNumber', {
                        rules: [{ required: true, message: 'Versicherungsnummer' }],
                    })(
                        <Input

                            placeholder="Versicherungsnummer"
                        />,
                    )}
                </Form.Item>
                <Form.Item>
                    {getFieldDecorator('securityToken', {
                        rules: [{ required: true, message: 'Kennwort' }],
                    })(
                        <Input

                            placeholder="Kennwort"
                        />,
                    )}
                </Form.Item>
                {isValidSecurityToken !== undefined && !isValidSecurityToken && (<div style={{color:"red", marginBottom:"10px"}}>Das Kennwort oder die Versicherungsnummer ist ungültig.</div>)}
                <Form.Item>
                    <Button type="primary" htmlType="submit" >Einloggen</Button>
                </Form.Item>
            </Form>
        </div>

    )
}

export default Form.create<SecurityTokenChallengeProps>()(SecurityTokenChallenge);