import { Button, Form, Input, Typography, notification, Icon } from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';
import React, { FormEvent, useContext } from 'react';
import InspectionContext from '../../context/inspection-context';
import { updateContactInformation } from '../../endpoints';


const { Text } = Typography;


// import 'antd/dist/antd.css';

interface ContactPersonDetailsProps extends FormComponentProps {
    refresh?: () => void;
}

const ContactPersonDetails = (props: ContactPersonDetailsProps) => {

    const { getFieldDecorator, getFieldValue } = props.form;
    const context = useContext(InspectionContext);

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        props.form.validateFields(async (err, values) => {
            if (!err) {
                context.inspection.contactPersonPhone = values.contactPersonPhone;
                context.inspection.contactPersonName = values.contactPersonName;
                await updateContactInformation(context.inspection, context.securityToken);

                props.refresh();

                notification.open({
                    message: 'Kontaktdaten gesendet',
                    description:
                        'Die angegebenen Kontaktdaten wurden erfolgreich an den Kontrolleur übermittelt. Er wird die Kontaktperson zur Vereinbarung eines Termins kontaktieren.',
                    icon: <Icon type="phone" style={{ color: '#108ee9' }} />,
                });
            }
        });
    }

    return (
        <InspectionContext.Consumer>
            {ctx => (
                <div>
                    <Form onSubmit={handleSubmit}>
                        <Form.Item>
                            {getFieldDecorator('contactPersonName', {
                                initialValue: ctx.inspection.contactPersonName,
                                rules: [{ required: true, message: 'Kontaktperson' }]

                            })(
                                <Input placeholder="Kontaktperson " />,
                            )}
                        </Form.Item>
                        <Form.Item>
                            {getFieldDecorator('contactPersonPhone', {
                                initialValue: ctx.inspection.contactPersonPhone,
                                rules: [{ required: true, message: 'Telefonnummer' }]
                            })(
                                <Input placeholder="Telefonnummer" />,
                            )}
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" >Kontaktdaten senden</Button>
                        </Form.Item>
                    </Form>
                </div>)
            }
        </InspectionContext.Consumer >

    );
}

export default Form.create<ContactPersonDetailsProps>()(ContactPersonDetails);