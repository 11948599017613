import React from 'react';
import { Row, Col, Descriptions } from 'antd';
import 'antd/dist/antd.css';
import InspectionContext from '../../context/inspection-context';
import { Address } from '../../models';

export const PropertyDetail = () => {

    const buildAddress = (address: Address): string => {

        return address.firstname + " " + address.name + ", "
            + address.street + " " + address.streetNumber + ", "
            + address.zipCode + " " + address.city;
    }

    return (
        <InspectionContext.Consumer>
            {ctx => (<div>
                <h3>Gebäude</h3>

                <Row>
                    <Col span={10}>Vers.-Nr.</Col>
                    <Col span={14}>{ctx.inspection.assuranceNumber}</Col>
                </Row>
                <Row>
                    <Col span={10}>Standort</Col>
                    <Col span={14}>{ctx.inspection.location}</Col>
                </Row>
                <Row>
                    <Col span={10}>Nutzung</Col>
                    <Col span={14}>{ctx.inspection.usage}</Col>
                </Row>
                <Row>
                    <Col span={10}>Gemeinde</Col>
                    <Col span={14}>{ctx.inspection.municipality}</Col>
                </Row>
                <Row>
                    <Col span={10}>Verwalter</Col>
                    <Col span={14}>{buildAddress(ctx.inspection.administrator)}</Col>
                </Row>
                {/* <Descriptions bordered layout="horizontal" size="small" column={1}>
                    <Descriptions.Item label="Vers.-Nr.">{ctx.inspection.assuranceNumber}</Descriptions.Item>
                    <Descriptions.Item label="Standort">{ctx.inspection.location}</Descriptions.Item>
                    <Descriptions.Item label="Nutzung">{ctx.inspection.usage}</Descriptions.Item>
                    <Descriptions.Item label="Gemeinde">{ctx.inspection.municipality}</Descriptions.Item>
                    <Descriptions.Item label="Eigentümer">{buildAddress(ctx.inspection.owner)}</Descriptions.Item>
                </Descriptions> */}
            </div>)}
        </InspectionContext.Consumer>


    );
}