import React from 'react';

import './App.css';
import { InspectionContainer } from '../blitzschutz';
import SecurityTokenChallenge from '../authorization/security-token-challenge'
import { Row, Col } from 'antd';
import { Typography } from 'antd';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import logoAfs from './../../assets/images/gvsg_logo.png'

const { Text } = Typography;

export const Root = () => {
    const headerClass = undefined;
    //  process.env.REACT_APP_RUNTIME_ENVIRONMENT === "Development" ? "devHeader" :
    //     process.env.REACT_APP_RUNTIME_ENVIRONMENT === "Test" ? "testHeader" : undefined;

        

    return (
        <div>
            <Row>
                <Col xs={{ span: 0 }} sm={{ span: 6 }} />
                <Col xs={{ span: 24 }} sm={{ span: 12 }}>

                    <div style={{ maxWidth: 800, padding: 15 }}>
                        <div className={headerClass}>
                            <img src={logoAfs} />
                        </div>

                        <div style={{ marginTop: 20 }}>
                            <Route exact={true} path="/" Component={SecurityTokenChallenge}>
                                <SecurityTokenChallenge />
                            </Route>

                            <Route exact={true} path="/:assuranceNumber">
                                <InspectionContainer securityToken="5x32-20sk" assuranceNumber="02.01554" />
                            </Route>

                            <Route exact={true} path="/:assuranceNumber/:securityToken" render={(routeProps) => {
                                return (
                                    <InspectionContainer
                                        assuranceNumber={routeProps.match.params.assuranceNumber}
                                        securityToken={routeProps.match.params.securityToken} />
                                )
                            }}>
                            </Route>
                        </div>
                    </div>
                </Col>
                <Col xs={{ span: 0 }} sm={{ span: 6 }} />
            </Row>
        </div >
    );
}