import { Col, Row, Typography } from 'antd';
import 'antd/dist/antd.css';
import dayjs from 'dayjs';
import React from 'react';
import InspectionContext from '../../context/inspection-context';
import { ConfirmationPersonFunction } from '../../models';

const { Text } = Typography;

export const ConfirmationSummary = () => {

    const contactPersonFunctionDisplayName = (functionId: number): string => {
        if (functionId === ConfirmationPersonFunction.Administrator) return "Verwalter";
        if (functionId === ConfirmationPersonFunction.Janitor) return "Hauswart";
        if (functionId === ConfirmationPersonFunction.Owner) return "Eigentümer";
        if (functionId === ConfirmationPersonFunction.Tinsmith) return "Spengler";
        if (functionId === ConfirmationPersonFunction.Electrician) return "Elektriker";
    };

    const systemStateDisplayName = (systemStateId: number): string => {
        if (systemStateId === 479) return "Abgeschätzte Anlage";
        if (systemStateId === 484) return "Anlage demontiert";
        if (systemStateId === 480) return "in Mängelbehebung";
        if (systemStateId === 482) return "in Umbau";
        if (systemStateId === 483) return "keine Anlage vorhanden";
        if (systemStateId === 485) return "Kontrolle annulliert";
        if (systemStateId === 481) return "Mängelfrei";
        if (systemStateId === 478) return "Meldung GVA";
        if (systemStateId === 486) return "Unterbruch durch Subvention";
        return "unbekannt";
    };

    const labelColumnStyle =
    {
        paddingTop: 2,
        paddingBottom: 2,
        paddingRight: 2,
    };
    return (
        <InspectionContext.Consumer>
            {ctx => (<div style={{ marginTop: "30px" }} >
                <h3>Mängelbehebung</h3>

                <Row>
                    <Col span={10} style={labelColumnStyle}>Name</Col>
                    <Col span={14}>{ctx.inspection.confirmationInformation.contactLastname}</Col>
                </Row>

                <Row>
                    <Col span={10} style={labelColumnStyle}>Vorname</Col>
                    <Col span={14}>{ctx.inspection.confirmationInformation.contactFirstname}</Col>
                </Row>

                <Row>
                    <Col span={10} style={labelColumnStyle}>Telefonnummer</Col>
                    <Col span={14}>{ctx.inspection.confirmationInformation.contactPhone}</Col>
                </Row>

                <Row>
                    <Col span={10} style={labelColumnStyle}>Funktion</Col>
                    <Col span={14}>{contactPersonFunctionDisplayName(ctx.inspection.confirmationInformation.contactPersonFunction)}</Col>
                </Row>

                <Row>
                    <Col span={10} style={labelColumnStyle}>Bemerkungen</Col>
                    <Col span={14}>{ctx.inspection.confirmationInformation.description}</Col>
                </Row>

                <Row>
                    <Col span={10} style={labelColumnStyle}>Bestätigt am</Col>
                    <Col span={14}>{dayjs(ctx.inspection.confirmationInformation.confirmationDate).format('DD.MM.YYYY')}</Col>
                </Row>

                <Row>
                    <Col span={10} style={labelColumnStyle}>Status</Col>
                    <Col span={14}>{systemStateDisplayName(ctx.inspection.systemStatus)}</Col>
                </Row>

            </div>)}
        </InspectionContext.Consumer>


    );
}

