import axios from '../environment/axios';
import { PortalInspection, DocumentResource } from '../models';

export async function getInspection(assuranceNumber: string, securityToken: string): Promise<PortalInspection> {
    const result = await axios.get(`portal/inspection/${assuranceNumber}/${securityToken}`);

    if (!result || !result.data) return null;

    return result.data;
}

/*  const result = await axios.post<Document>(`/documents`, document);
  if (!result || !result.data) return null;
  return result.data;*/
export async function createDocument(
    inspection: PortalInspection,
    securityToken: string,
    filename: string
): Promise<DocumentResource> {
    const result = await axios.post(`portal/inspection/${inspection.id}/${securityToken}/document`, {
        filename: filename,
    });

    if (!result || !result.data) return null;

    return result.data;
}

export async function deleteDocument(documentId: string, securityToken: string): Promise<string> {
    const result = await axios.delete(`portal/documents/${documentId}/${securityToken}`);

    if (!result || !result.data) return null;

    return result.data;
}

export async function uploadDocument(signedUrl: string, file: any): Promise<void> {
    await axios.put(signedUrl, file, {
        headers: {
            'x-ms-blob-type': 'BlockBlob',
        },
    });
}

export async function updateContactInformation(inspection: PortalInspection, securityToken: string): Promise<void> {
    const result = await axios.put(`portal/inspection/${inspection.id}/${securityToken}`, inspection);

    if (!result || !result.data) return null;

    return result.data;
}

export async function confirmFixedFindings(inspection: PortalInspection, securityToken: string): Promise<void> {
    const result = await axios.put(`portal/inspection/${inspection.id}/${securityToken}/confirm`, inspection);

    if (!result || !result.data) return null;

    return result.data;
}
