import { Typography } from 'antd';
import React from 'react';
import { InspectionKind, PortalInspection, PortalInspectionState } from '../../models';
import { AppointmentInfo } from './appointment-info';
import { ConfirmationSummary } from './confirmation-summary';
import ContactPersonDetails from './contact-person-details';
import FindingConfirmation from './finding-confirmation';


const { Text } = Typography;

export interface InspectionStepsContainerProps {
    inspection: PortalInspection;
    onLoadInspection: () => void;
}

export const InspectionStepsContainer = (props: InspectionStepsContainerProps) => {

    const { inspection, onLoadInspection } = props;

    const showUpcomingAppointmentInfoText = inspection &&
        inspection.portalInspectionState === PortalInspectionState.ContactDataProvided;

    const showUpcomingFindingsInfoText = inspection &&
        inspection.portalInspectionState === PortalInspectionState.AppointmentScheduled;

    const showContactPersonForm = inspection && inspection.portalInspectionState === PortalInspectionState.Open;

    const showAppointmentInfo = inspection && !showContactPersonForm;

    const showFindingConfirmation = inspection && inspection.portalInspectionState === PortalInspectionState.ConfirmationRequired;

    const showConfirmationSummary = inspection &&
        (inspection.portalInspectionState === PortalInspectionState.Confirmed ||
            inspection.portalInspectionState === PortalInspectionState.Processed);

    const title =
        inspection?.kind === InspectionKind.Initial ? "Abnahme Blitzschutzanlage" :
            inspection?.kind === InspectionKind.Periodical ? "Periodische Blitzschutzkontrolle" :
                "Blitzschutzkontrolle";

    document.title = title;

    return (
        <div>
            {showContactPersonForm && (<div style={{ marginTop: "10px", marginBottom: "10px" }}>
                <Text>
                    Damit unser Kontrolleur {inspection.inspectorName} mit
                Ihnen einen Termin für die Kontrolle vereinbaren kann, sind wir dankbar, wenn Sie uns
                die zuständige Kontaktperson bekannt geben:</Text>

                <ContactPersonDetails refresh={onLoadInspection} />
            </div>)}

            {showAppointmentInfo && <AppointmentInfo />}

            {showUpcomingAppointmentInfoText && (<div style={{ marginTop: 10 }}><Text>Hier sehen Sie den vereinbarten Termin, sobald dieser zwischen der Kontaktperson und dem Kontrolleur festgelegt wurde.</Text></div>)}
            {showUpcomingFindingsInfoText && (<div style={{ marginTop: 10 }}><Text>Sobald die Kontrolle durchgeführt wurde, können Sie hier den aktuellen Status der Kontrolle mitverfolgen.</Text></div>)}

            {showFindingConfirmation && <FindingConfirmation refresh={onLoadInspection} />}

            {showConfirmationSummary && <ConfirmationSummary />}
        </div>

    );
}